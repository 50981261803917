import { useLocalization } from "@shared-ui/localization-context";
import { DiscoveryRecommendationStrategy } from "@shared-ui/retail-recommendation-module";
import { DeviceType } from "__generated__/typedefs";
import { ExtendedContextStore, Geo } from "src/typings/flexFramework/FlexDefinitions";

export const getTitle = (title: string): any => {
  const { formatText } = useLocalization();
  switch (title) {
    case "Similar properties": {
      return formatText("hotels.dateless.similarProperties.carousel.heading.title");
    }
    default:
      return undefined;
  }
};

export const getLocationDetails = (location: Geo, strategy: DiscoveryRecommendationStrategy): any => {
  if (!location) return null;

  switch (strategy) {
    case DiscoveryRecommendationStrategy.SPONSORED: {
      return {
        id: location.parent && location.parent.id ? String(location.parent.id) : null,
        type: location.parent && location.parent.type ? String(location.parent.type) : null,
      };
    }
    default:
      return {
        id: location.id ? String(location.id) : null,
        type: location.type ? String(location.type) : null,
      };
  }
};

export const getSearchCriteria = (strategy: DiscoveryRecommendationStrategy, context: ExtendedContextStore): any => {
  switch (strategy) {
    case DiscoveryRecommendationStrategy.SPONSORED: {
      return {
        searchCriteria: {
          selections: [
            {
              id: "minCarouselAds",
              value: getMinCarouselAds(context.deviceInformation?.type).toString(),
            },
          ],
        },
      };
    }
    default:
      return null;
  }
};

function getMinCarouselAds(type: DeviceType | undefined) {
  switch (type) {
    case "MOBILE_PHONE":
      return 2;
    case "MOBILE_TABLET":
      return 3;
    case "DESKTOP":
      return 4;
    default:
      return 4;
  }
}
